import {Injectable, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WsCarPostBidService} from './ws-car-post-bid.service';
import {environment} from '../../environments/environment';
import {IAuth, IUserCreate, IUser} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: IUser;
  onLogin = new EventEmitter<IUser>();
  onLogout = new EventEmitter<IUser>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private wsCarPostBidService: WsCarPostBidService,
  ) {
    if (localStorage.getItem('user')) {
      try {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.user.birthday = new Date(this.user.birthday);
      } catch (e) {}
    }
  }

  login({email, password, remember}: IAuth): Observable<object> {
    return new Observable<IUser>((subscriber) => {
      this.wsCarPostBidService.oneSignal.getIds().then(({userId}) => {
        this.loginAction(email, password, remember, userId)
          .then((user: IUser) => {
            subscriber.next(user);
            subscriber.complete();
          })
          .catch((e) => {
            subscriber.error(e);
            subscriber.complete();
          });
      }, () => {
        this.loginAction(email, password, remember)
          .then((user: IUser) => {
            subscriber.next(user);
            subscriber.complete();
          })
          .catch((e) => {
            subscriber.error(e);
            subscriber.complete();
          });
      });
    });
  }

  async loginAction(email: string, password: string, remember?: boolean, userId?: string): Promise<IUser> {
    try {
      const headers: { fcmtoken?: string } = {};

      if (userId) {
        headers.fcmtoken = userId;
      }

      const user: IUser = await this.http.post(
        environment.api + '/auth',
        {
          email: email.trim().toLowerCase(),
          password: password.trim()
        },
        {
          headers,
        }
      ).toPromise() as IUser;
      this.user = user;
      this.user.birthday = new Date(this.user.birthday);
      this.onLogin.emit(user);

      if (remember) {
        this.saveUser();
      }

      return user;
    } catch (e) {
      this.onLogin.error(e);
      throw e;
    }
  }

  saveUser(user?: IUser) {
    localStorage.setItem('user', JSON.stringify(user || this.user));
  }

  logout(): void {
    const user = this.user;
    this.user = undefined;
    localStorage.removeItem('user');
    this.router.navigate(['login']).finally();
    this.onLogout.emit(user);
  }

  register(userData: IUserCreate): Observable<object> {
    return this.http.post(
      environment.api + '/register',
      userData,
    );
  }

  resetPassword(email: string): Observable<object> {
    return this.http.post(
      environment.api + '/send-reset-password-code',
      {
        email,
      },
    );
  }

  validateResetPasswordCode(email: string, password: string, code: string): Observable<object> {
    return this.http.post(
      environment.api + '/validate-reset-password-code',
      {
        email,
        password,
        code,
      },
    );
  }

  sendValidationCode(email: string, phone: string, hash: string): Observable<object> {
    return this.http.post(
      environment.api + '/send-validation-code',
      {
        email,
        phone: '+56' + phone.replace(/^\+56/, ''),
        hash,
      },
      {
        headers: {
          Authorization: this.user.token,
        }
      }
    );
  }

  validateValidationCode(code: string): Observable<object> {
    return this.http.post(
      environment.api + '/validate-validation-code',
      {
        code,
      },
      {
        headers: {
          Authorization: this.user.token,
        }
      }
    );
  }

  userDataUpdate(userData: { email: string, phone: string, password?: string }): Observable<object> {
    return this.http.patch(
      environment.api + '/user/' + this.user.id,
      userData,
      {
        headers: {
          Authorization: this.user.token,
        }
      }
    );
  }

  renewToken(): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(
      environment.api + '/renew-credential',
      {}
    );
  }

  async loginExpress(loginExpressToken, loginExpressId, userEmail) {
    const user: IUser = await this.http.post(
      environment.api + '/auth-login-express',
      {
        loginExpressToken,
        loginExpressId,
        userEmail,
      }
    ).toPromise() as IUser;
    this.user = user;
    this.user.birthday = new Date(this.user.birthday);
    this.onLogin.emit(user);
    this.saveUser();
  }
}
