import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ITransaction, TransactionEntityStatus} from '../interfaces';
// import * as socketIO from 'socket.io-client';
import {TransactionService} from './transaction.service';

@Injectable({
  providedIn: 'root',
})
export class WsTransactionService {
  // private io: socketIO.Socket;

  constructor(
    private transactionService: TransactionService,
  ) {
    /*this.io = socketIO.io(environment.ws.url + environment.ws.transaction, {
      transports: ['websocket'],
    });*/
  }

  async onTransactionUpdate(publicToken: string): Promise<ITransaction> {
    return new Promise<ITransaction>((resolve, reject) => {
      let resolved = false;
      let interval;
      setTimeout(() => {
        if (!resolved) {
          reject('Transaction timeout');
        }
      }, 300000);
      const subscriptionPath = 'pay-car-post-' + publicToken + '-update';
      /*this.io.on(subscriptionPath, (transaction: ITransaction) => {
        this.io.off(subscriptionPath);
        if (interval) {
          clearInterval(interval);
        }
        resolved = true;
        resolve(transaction);
      });*/
      interval = setInterval(() => {
        this.transactionService.getTransaction(publicToken)
          .subscribe((transaction: ITransaction) => {
            if (TransactionEntityStatus.INITIALIZED !== transaction.status) {
              // this.io.off(subscriptionPath);
              if (interval) {
                clearInterval(interval);
              }
              resolved = true;
              resolve(transaction);
            }
          });
      }, 3000);
    });
  }
}
