export const environment = {
  production: false,
  bidIncrement: 100000,
  //api: 'http://127.0.0.1:3000',
  // api: 'https://dev.api.autazo.cl/api',
  api: 'https://api.autazo.cl',
  data: 'https://data.autazo.cl',
  frontendUrl: 'https://dev.autazo.cl',
  googleLoginProvider: '937595661279-6ev76ta9mepiutoljeflba47jsaea60k.apps.googleusercontent.com',
  facebookLoginProvider: '278622606304710',
  ws: {
    url: 'https://dev.rt.autazo.cl',
    carPostBid: '/car-post-bid',
    transaction: '/pay-car-post',
  },
  oneSignal: {
    appId: '8b35f50f-9627-421c-88f9-8e97eb0713a3',
    googleProjectId: '937595661279',
  }
};
