import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {}

  cloneRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        authorization: this.authService.user.token
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.authService.user) {
      request = this.cloneRequest(request);
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403 && this.authService.user) {
          return this.authService.renewToken().pipe(
              catchError(() => {
                  this.authService.logout();
                  return throwError(err);
              } ),
              mergeMap(({ token }) => {
              this.authService.user.token = token;
              this.authService.saveUser();
              return next.handle(this.cloneRequest(request));
            })
          );
        }
        return throwError(err);
      })
    );
  }
}
