export const timeAgo = (date: string | Date): string => {
    const dateAgo = new Date(date);
    const currentDate = new Date();
    const timeDifference = Number(currentDate) - Number(dateAgo);

    if (timeDifference / 2592000000 > 1) {
        const months = Math.floor(timeDifference / 2592000000);
        return months + ' mes' + (months > 1 ? 'es' : '');
    } else if (timeDifference / 86400000 > 1) {
        const days = Math.floor(timeDifference / 86400000);
        return days + ' día' + (days > 1 ? 's' : '');
    } else if (timeDifference / 3600000) {
        const hours = Math.floor(timeDifference / 3600000);
        return hours + ' hora' + (hours > 1 ? 's' : '');
    } else {
        return 'poco tiempo';
    }
};
export const numberFormat = n => {
    const negativeSign = n < 0 ? '-' : '';

    // tslint:disable-next-line:radix
    const i = parseInt((n = Math.abs(Number(n) || 0).toFixed(0))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
        negativeSign +
        (j ? i.substr(0, j) + '.' : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + '.') +
        (0
            ? ',' +
            Math.abs(n - Number(i))
                .toFixed(0)
                .slice(2)
            : '')
    );
};

