import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ICarPost, ILicensePlateData, IUser} from '../interfaces';
import {AuthenticationService} from './authentication.service';

interface ICarsFilter {
  page?: number;
  order?: string;
  brand?: string;
  model?: string;
  typeTransmission?: string;
  typeFuel?: string;
  minKilometers?: number;
  maxKilometers?: number;
  minYear?: number;
  maxYear?: number;
  minPrice?: number;
  maxPrice?: number;
  q?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CarPostService {
  public currentLicensePlateData: ILicensePlateData;
  public isOwner: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) {
    if (localStorage.getItem('currentLicensePlateData')) {
      try {
        this.currentLicensePlateData = JSON.parse(localStorage.getItem('currentLicensePlateData'));
      } catch (e) {
      }
    }
  }

  getCarPost(filters?: ICarsFilter): Observable<any> {
    const cleanedFilters: { [key: string]: string } = {};

    Object.keys(filters || {}).forEach((filterKey) => {
      if (filters[filterKey]) {
        cleanedFilters[filterKey] = String(filters[filterKey]);
      }
    });

    return this.http.get(
      environment.api + '/car-post',
      {
        params: {
          ...cleanedFilters
        }
      }
    );
  }

  getCarPostDetail(id: number): Observable<any> {
    return this.http.get(
      environment.api + '/car-post/' + id
    );
  }
  getCarPostDetailLicence(licensePlate: string): Observable<any> {
    return this.http.get(
      environment.api + '/car-post-details/' + licensePlate
    );
  }


  getFilters(): Observable<any> {
    return this.http.get(
      environment.api + '/car-post-filters'
    );
  }

  bidCarPost(carPost: ICarPost, bid: number): Observable<any> {
    return this.http.post(
      environment.api + '/car-post/' + carPost.id + '/bid',
      {
        bid,
      },
    );
  }

  uploadImage(document: File): Observable<any> {
    const formData = new FormData();

    formData.append('image', document, document.name);

    return this.http.post(
      environment.api + '/car-post-image',
      formData,
    );
  }

  uploadMobileImage(blob: Blob, filename: string): Observable<any> {
    const formData = new FormData();

    formData.append('image', blob, filename);

    return this.http.post(
      environment.api + '/car-post-image',
      formData,
    );
  }

  createCarPost(carPost: ICarPost): Observable<any> {
    return this.http.post(
      environment.api + '/car-post',
      {...carPost},
    );
  }

  getMyCarPosts(): Observable<any> {
    return this.http.get(
      environment.api + '/user/' + this.authService.user.id + '/car-post',
    );
  }

  getMyReservedCarPosts(): Observable<any> {
    return this.http.get(
      environment.api + '/user/' + this.authService.user.id + '/reserved-car-post',
    );
  }

  postSaleDirect(id: number): Observable<any> {
    return this.http.patch(
      environment.api + '/car-post/' + id + '/direct-sale',
      {},
    );
  }

  getMyCarPostsBids(): Observable<any> {
    return this.http.get(
      environment.api + '/user/' + this.authService.user.id + '/bids',
    );
  }

  saveCurrentLicensePlateData() {
    if (this.currentLicensePlateData) {
      localStorage.setItem('currentLicensePlateData', JSON.stringify(this.currentLicensePlateData));
    } else {
      localStorage.removeItem('currentLicensePlateData');
    }
  }

  getCarPostConfigure(id: string, loginExpressToken: string, loginExpressId: string, userEmail: string): Observable<any> {
    return this.http.get(
      environment.api + '/car-post/' + id + '/configure-car-post',
      {
        params: {
          loginExpressToken,
          loginExpressId,
          userEmail,
        }
      }
    );
  }

  patchCarPost(lowValue:number,commercialValue:number,id: number, user: IUser,status:String): Observable<any> {
    return this.http.patch(
      environment.api + '/car-post/'+id,
      {
        id,
        lowValue,
        commercialValue,
        status,
        "user":user
      },
    );
  }

  patchCarPostTypeSale(
    body: {
      carPostId: string,
      loginExpressToken: string,
      loginExpressId: string,
      userEmail: string,
      customPriceRange: number,
      typeSale: number,
      termsAndConditions: boolean,
    }
    ): Observable<any> {
    return this.http.patch(
      environment.api + '/car-post/' + body.carPostId + '/set-type-sale',
      body
    );
  }
}
