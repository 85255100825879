export enum TransactionEntityStatus {
  INITIALIZED = 'INITIALIZED',
  AUTHORIZED = 'AUTHORIZED',
  REVERSED = 'REVERSED',
  FAILED = 'FAILED',
  NULLIFIED = 'NULLIFIED',
  PARTIALLY_NULLIFIED = 'PARTIALLY_NULLIFIED',
  CAPTURED = 'CAPTURED',
}

export enum TransactionEntityTypes {
  WEBPAYPLUS = 'WEBPAYPLUS',
  MONEY_TRANSFER = 'MONEY_TRANSFER', // Transferencia
  BANK_CHECK = 'BANK_CHECK', // Cheque bancario
  MONEY_ORDER = 'MONEY_ORDER', // Vale vista
  WHATSAPP_ORDER = 'WHATSAPP_ORDER', // WSP
  FALABELLA_PAY = 'FALABELLA_PAY', // WSP
}

export interface ITransaction {
  vci?: string;
  amount?: number;
  status?: TransactionEntityStatus;
  type?: TransactionEntityTypes;
  buy_order?: string;
  session_id?: string;
  card_detail?: {
    card_number: string;
  };
  accounting_date?: string;
  transaction_date?: string | Date;
  authorization_code?: string;
  payment_type_code?: string;
  response_code?: number;
  installments_amount?: number;
  installments_number?: number;
}
