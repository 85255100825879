export * from './global.transformer';
export * from './car-post.transformer';

export function base64Extension(base64: string): string {
  if (base64.charAt(0) === '/') {
    return 'jpeg';
  } else if (base64.charAt(0) === 'R') {
    return 'gif';
  } else if (base64.charAt(0) === 'i') {
    return 'png';
  } else {
    return 'tiff';
  }
}

export function toBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = error => reject(error);
  });
}
