import {Injectable, EventEmitter} from '@angular/core';
import {environment} from '../../environments/environment';
import {ICarPost, IWSNewCarPostMsg} from '../interfaces';
import {transformers} from '../utils';
// import * as socketIO from 'socket.io-client';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class WsCarPostBidService {
  // private io: socketIO.Socket;
  onNewCarPostBid = new EventEmitter<IWSNewCarPostMsg>();
  notificationStarted = false;

  constructor(
    public oneSignal: OneSignal,
    private platform: Platform,
  ) {
    /*this.io = socketIO.io(environment.ws.url + environment.ws.carPostBid, {
      transports: ['websocket'],
    });*/

    this.carPostBidListen();
  }

  notificationStart() {
    if (!this.notificationStarted) {
      this.notificationStarted = true;

      if (this.platform.is('cordova')) {
        this.oneSignal
          .startInit(environment.oneSignal.appId, this.platform.is('android') ? environment.oneSignal.googleProjectId : undefined)
          .inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification)
          .endInit();
      }
    }
  }

  carPostBidListen() {
    /*this.io.on('new-car-post-bid', (newCarPostMsg: IWSNewCarPostMsg) => {
      this.onNewCarPostBid.emit(newCarPostMsg);
    });*/
  }

  updateCarPosts(carPosts: ICarPost | ICarPost[], newCarPostMsg: IWSNewCarPostMsg) {
    const carPostsArr = Array.isArray(carPosts) ? carPosts : [carPosts];
    carPostsArr.forEach((carPost) => {
      if (String(newCarPostMsg.id) === String(carPost.id)) {
        carPost.bids = [newCarPostMsg.carPostBid, ...carPost.bids];
        Object.assign(carPost, transformers.auxMappingCarPost(carPost));
      }
    });
  }
}
