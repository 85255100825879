import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IBrandData, IBrandModelsData, IModelVersionsData } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private http: HttpClient,
  ) {}

  getBrands(name?: string): Observable<any> {
    if (name) {
      return this.http.get(
        environment.data + '/brands',
        {
          params: {
            name,
          }
        }
      );
    } else {
      return this.http.get(
        environment.data + '/brands'
      );
    }
  }

  getModelsByBrandId(brandId: number): Observable<any> {
    return this.http.get(
      environment.data + '/brands/' + brandId + '/models'
    );
  }

  getLicensePlateData(licensePlate: string): Observable<any> {
    return this.http.get(
      environment.data + '/get-vehicle-data/' + licensePlate
    );
  }

  getVehicleData(brand: string, model: string, year: string): Observable<any> {
    let brandName = brand;
    let modelName = model;
    return this.getBrands(brand).pipe(
      mergeMap((brandsData: IBrandData[]) => {
        brandName = brandsData[0].name;
        return this.http.get(
          environment.data + '/brands/' + brandsData[0].id,
          {
            params: {
              name: model,
            }
          }
        );
      }),
      mergeMap((brandModels: IBrandModelsData) => {
        modelName = brandModels.models[0].name;
        return this.http.get(
          environment.data + '/models/' +  brandModels.models[0].id,
          {
            params: {
              year,
            }
          }
        );
      }),
      map((modelVersionsData: IModelVersionsData) => {
        return {
          versions: modelVersionsData.versions,
          brand: brandName.toUpperCase(),
          model: modelName.toUpperCase(),
        };
      })
    );
  }
}
