import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {TransactionEntityTypes} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(
    private http: HttpClient,
  ) {}

  createTransaction(carPostId: number | string, bid?: number, type?: TransactionEntityTypes): Observable<any> {
    let body;

    if (bid || type) {
      body = {};
      if (bid) {
        body.bid = bid;
      }
      if (type) {
        body.type = type;
      }
    }

    return this.http.post(
      environment.api + '/transaction/car-post/' + carPostId,
      body,
    );
  }

  commitTransaction(token: string): Observable<any> {
    return this.http.post(
      environment.api + '/transaction/commit',
      {
        token
      },
    );
  }

  verifyCarPostParticipation(carPostId: number): Observable<any> {
    return this.http.post(
      environment.api + '/transaction/car-post/' + carPostId + '/participate',
      undefined,
    );
  }

  getTransaction(publicToken: string): Observable<any> {
    return this.http.get(
      environment.api + '/transaction/' + publicToken,
    );
  }
}
