export enum CarPostEntityTypeFuel {
  BENZINE = 'Bencina',
  DIESEL = 'Diesel',
  ELECTRIC = 'Eléctrico',
  HYBRID = 'Híbrido',
}
export enum CarPostEntityTypeTransmission {
  AUTOMATIC = 'Automático',
  MECHANICAL = 'Mecánico',
}
export enum CarPostEntityTypeSale {
  AUCTION = 'AUCTION',
  DIRECT_SALE = 'DIRECT_SALE',
}
export enum CarPostEntityStatus {
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
  DELETED = 'DELETED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}
export const yesOrNotTypes = ['Si', 'No'];
export const ownersOptions = ['Uno', 'Dos', 'Tres o Más'];
