import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ILicensePlateData, IUser} from '../interfaces';
import { IAutoRed } from '../interfaces/autored.interface';

@Injectable({
  providedIn: 'root',
})
export class UtilPostService {
  public currentLicensePlateData: ILicensePlateData;

  constructor(
    private http: HttpClient,
  ) {
    if (localStorage.getItem('currentLicensePlateData')) {
      try {
        this.currentLicensePlateData = JSON.parse(localStorage.getItem('currentLicensePlateData'));
      } catch (e) {
      }
    }
  }

  getVersionId(packageName: string, ios: boolean = true): Observable<any> {
    if (ios) {
      packageName = 'id1533594449';
      return this.http.post(environment.api + '/apple-id/' + packageName, {});
    } else {
      return this.http.post(environment.api + '/android-id/' + packageName, {});
    }
  }

  getAutoRed(licencePlate: string, km: string = ''): Observable<IAutoRed> {
    if(km)  km =  '/' + km;
    return this.http.get<IAutoRed>(environment.api + '/autored/' + licencePlate + km);
  }
  notificationFailed(data:any) {

    return  this.http.patch(environment.api + '/notification-fail',data);
  }
  

}
