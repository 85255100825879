import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(
    private http: HttpClient,
  ) {}

  createReservation(carPostId: string | number): Observable<any> {
    return this.http.post(
      environment.api + '/car-post/' + carPostId + '/reservation',
      {}
    );
  }
}
