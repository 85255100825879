import { CarPostEntityTypeFuel, CarPostEntityTypeTransmission } from '../../constants';
import { ICarPost, ICarPostPaginator, ICarPostFilters } from '../../interfaces';
import { timeAgo } from './global.transformer';

export const auxMappingCarPost = (carPost: ICarPost): ICarPost => {
    return {
        ...carPost,
        created: carPost.created ? new Date(carPost.created) : undefined,
        expiration: carPost.expiration ? new Date(carPost.expiration) : undefined,
        bids: carPost.bids ? carPost.bids.map((bid) => ({ ...bid, created: bid.created ? new Date(bid.created) : undefined })) : undefined,
        user: carPost.user ? {
            ...carPost.user,
            created: new Date(carPost.user.created),
            _createdAgo: timeAgo(carPost.user.created)
        } : undefined,
        _fullName: carPostName(carPost),
        _currentPrice: carPostCurrentPrice(carPost),
        _typeFuelName: carPostTypeFuelName(carPost),
        _typeTransmission: carPostTypeTransmission(carPost),
        _timeAgo: timeAgo(carPost.created),
        _participants: carPostBidsParticipants(carPost),
    };
};

export const auxMappingPaginator = (carPostPaginator: ICarPostPaginator): ICarPostPaginator => {
    return {
        ...carPostPaginator,
        items: carPostPaginator.items.map((carPost) => {
            return auxMappingCarPost(carPost);
        })
    };
};

export const carPostName = (carPost: ICarPost): string => {
    return `${carPost.brandName} ${carPost.modelName} ${carPost.versionName}`;
};

export const carPostCurrentPrice = (carPost: ICarPost): number => {
    let maxValue = carPost.lowValue;
    carPost.bids.forEach(({ bid }) => {
        if (bid > maxValue) {
            maxValue = bid;
        }
    });
    if (carPost.typeSale === 'DIRECT_SALE') {
        maxValue =  carPost.lowValue + ( carPost.lowValue * (carPost.customPriceRange / 100 ));
    }
    return maxValue;
};

export const carPostTypeFuelName = (carPost: ICarPost): string => {
    return typeof carPost.typeFuel !== 'undefined' ? CarPostEntityTypeFuel[carPost.typeFuel] : '';
};

export const carPostTypeTransmission = (carPost: ICarPost): string => {
    return typeof carPost.typeTransmission !== 'undefined' ? CarPostEntityTypeTransmission[carPost.typeTransmission] : '';
};

export const carPostBidsParticipants = (carPost: ICarPost) => {
    const participants: { [key: string]: { bids: number, mostValue: number } } = {};
    const userNames: { [key: string]: string } = {};

    carPost.bids.forEach((bid) => {
        if (!participants[bid.user.publicToken]) {
            participants[bid.user.publicToken] = { bids: 0, mostValue: 0 };
        }
        participants[bid.user.publicToken].bids++;
        userNames[bid.user.publicToken] = bid.user.firstName;
        if (participants[bid.user.publicToken].mostValue < bid.bid) {
            participants[bid.user.publicToken].mostValue = bid.bid;
        }
    });

    return Object.keys(participants).map(key => ({
        publicToken: key,
        user: userNames[key],
        bids: participants[key].bids,
        mostValue: participants[key].mostValue,
    }));
};

export const computeCarPostFilters = (filters: ICarPostFilters) => {
    const auxFilters: ICarPostFilters = { ...filters };
    const years = [];

    for (let i = auxFilters.minYear; i <= auxFilters.maxYear; i++) {
        years.push(String(i));
    }
    auxFilters.minYearList = [...years];
    auxFilters.maxYearList = [...years.reverse()];

    const kilometers = [];
    const kilometersFactor = 10000;
    let kilometersLimit = Math.floor(
        auxFilters.maxKilometers / kilometersFactor
    );
    if (kilometersLimit !== auxFilters.maxKilometers / kilometersFactor) {
        kilometersLimit++;
    }
    kilometersLimit = kilometersLimit * kilometersFactor;

    for (let i = 0; i <= kilometersLimit; i += kilometersFactor) {
        kilometers.push(String(i));
    }
    auxFilters.minKilometersList = [...kilometers];
    auxFilters.maxKilometersList = [...kilometers.reverse()];

    const prices = [];
    const priceFactor = 5000000;
    let pricesLimit = Math.floor(auxFilters.maxLowValue / priceFactor);
    if (pricesLimit !== auxFilters.maxLowValue / priceFactor) {
        pricesLimit++;
    }
    pricesLimit = pricesLimit * priceFactor;

    for (let i = 0; i <= pricesLimit; i += priceFactor) {
        prices.push(String(i));
    }
    auxFilters.minPriceList = [...prices];
    auxFilters.maxPriceList = [...prices.reverse()];
    return auxFilters;
};
